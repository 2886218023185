<script setup lang="ts">
import LeagueModeService from '@/services/LeagueModeService';
import { onMounted, ref } from 'vue';

const totalWorld = ref<number>(0);

onMounted(async () => {
    const google = window as any
    google.google.charts.load('current', { 'packages': ['geochart'] });
    google.google.charts.setOnLoadCallback(drawWorldMap);
    google.google.charts.setOnLoadCallback(drawUSMap);

    async function drawWorldMap() {
        let userData = await LeagueModeService.GetUserWorldMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['Country', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(x.CountryCode);
            temp.push(x.Count);
            totalWorld.value += x.Count;
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('world_div'));

        chart.draw(data, options);
    }

    async function drawUSMap() {
        let userData = await LeagueModeService.GetUserUSAMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['State', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(`US-${x.Region}`);
            temp.push(x.Count);
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { region: "US", resolution: "provinces", responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('us_div'));

        chart.draw(data, options);
    }
})
</script>


<template>
    <v-badge style="margin-top: 2rem;" :content="totalWorld">
        <v-icon color="grey lighten-1" icon="fas fa-user-circle" large></v-icon>
    </v-badge>
    <br />
    <div id="world_div"></div>
    <br />
    <div id="us_div"></div>
</template>

<style scoped></style>
