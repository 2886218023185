import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import LeagueModeService from '@/services/LeagueModeService';
import { onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeagueModeAdminComponent',
  setup(__props) {

const totalWorld = ref<number>(0);

onMounted(async () => {
    const google = window as any
    google.google.charts.load('current', { 'packages': ['geochart'] });
    google.google.charts.setOnLoadCallback(drawWorldMap);
    google.google.charts.setOnLoadCallback(drawUSMap);

    async function drawWorldMap() {
        let userData = await LeagueModeService.GetUserWorldMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['Country', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(x.CountryCode);
            temp.push(x.Count);
            totalWorld.value += x.Count;
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('world_div'));

        chart.draw(data, options);
    }

    async function drawUSMap() {
        let userData = await LeagueModeService.GetUserUSAMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['State', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(`US-${x.Region}`);
            temp.push(x.Count);
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { region: "US", resolution: "provinces", responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('us_div'));

        chart.draw(data, options);
    }
})

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_badge, {
      style: {"margin-top":"2rem"},
      content: totalWorld.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          color: "grey lighten-1",
          icon: "fas fa-user-circle",
          large: ""
        })
      ]),
      _: 1
    }, 8, ["content"]),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { id: "world_div" }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { id: "us_div" }, null, -1))
  ], 64))
}
}

})